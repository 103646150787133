import React from 'react'

import Picker from './components/Picker'
import { StylesProvider } from '@mui/styles';

function App() {
  return (
    <StylesProvider injectFirst>
      <Picker />
    </StylesProvider>
  );
}

export default App;
